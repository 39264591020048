<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Produk *
                </label>
                <Dropdown v-model="form.selected_product" :options="products" optionLabel="name" placeholder="Pilih Produk" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Kode Denom *
                </label>
                <InputText type="text" placeholder="Kode Denom" v-model="form.code" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Denom *
                </label>
                <InputText type="text" placeholder="Nama Denom" v-model="form.name" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Type *
                </label>
                <Dropdown v-model="form.selected_type" :options="types" optionLabel="label" placeholder="Pilih Tipe" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Deskripsi *
                </label>
                <Textarea rows="5" placeholder="Deskripsi" v-model="form.description" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Amount *
                </label>
                <InputNumber v-model="form.amount" mode="currency" currency="IDR" locale="id-ID" :minFractionDigits="0" :min="0" />
            </div>
            <div class="grid">
                <div class="col-12 sm:col-6">
                    <div class="field mb-4">
                        <label class="text-black text-14 roboto-medium">
                            Harga Denom *
                        </label>
                        <InputNumber v-model="form.price" mode="currency" currency="IDR" locale="id-ID" :minFractionDigits="0" :min="0" />
                    </div>
                </div>
                <div class="col-12 sm:col-6">
                    <div class="field mb-4">
                        <label class="text-black text-14 roboto-medium">
                            Harga Init Denom *
                        </label>
                        <InputNumber v-model="form.init_price" mode="currency" currency="IDR" locale="id-ID" :minFractionDigits="0" :min="0" />
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col-12 sm:col-6">
                    <div class="field mb-4">
                        <label class="text-black text-14 roboto-medium">
                            Biller Fee
                        </label>
                        <InputNumber v-model="form.biller_fee" mode="currency" currency="IDR" locale="id-ID" :minFractionDigits="0" :min="0" />
                    </div>
                </div>
                <div class="col-12 sm:col-6">
                    <div class="field mb-4">
                        <label class="text-black text-14 roboto-medium">
                            Source of Fund Fee
                        </label>
                        <InputNumber v-model="form.sof_fee" mode="currency" currency="IDR" locale="id-ID" :minFractionDigits="0" :min="0" />
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['data', 'products'],
    data() {
        return {
            form: {
                id: null,
                code: '',
                name: '',
                description: '',
                amount: 0,
                price: 0,
                init_price: 0,
                biller_fee: 0,
                sof_fee: 0,
                selected_product: '',
                product_id: '',
                product_name: '',
                selected_type: {},
                type: '',
                is_submit: false,
            },
            types: [
                {label: 'Pulsa', value: 'PURCHASE_CREDIT'},
                {label: 'Paket Data', value: 'PURCHASE_DATA'},
            ],
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.product_name = this.form.selected_product ? this.form.selected_product['name'] : ''
                this.form.product_id = this.form.selected_product ? this.form.selected_product['id'] : ''
                this.form.type = this.form.selected_type ? this.form.selected_type['value'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.selected_product = this.products.find(obj => obj.id == this.form.product_id);
            this.form.selected_type = this.types.find(obj => obj.value == this.form.type);
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped></style>
